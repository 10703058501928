





































import { Component, Prop, Vue } from 'vue-property-decorator';
import LoadingContent from '@/components/LoadingContent.vue';

@Component({
  components: { LoadingContent },
})
export default class VbDialog extends Vue {
  @Prop({
    type: Number,
    default: 600,
  })
  width!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  isLoading!: boolean;

  @Prop({
    type: String,
    default: '',
  })
  title!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  useDefaultActions!: boolean;

  close() {
    this.$emit('close');
  }
}
