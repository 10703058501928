<template>
  <div v-if="lesson" class="schedule-time">
    <div>
      {{ lesson.lessonStartDay }}
      c
      {{ lesson.lessonStartTime }}
      до
      {{
        lesson.lessonStartDay === lesson.lessonFinishDay
          ? lesson.lessonFinishTime
          : `${lesson.lessonFinishDay} ${lesson.lessonFinishTime}`
      }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.schedule-time{
  display: inline-block;
}
</style>
