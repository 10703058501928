













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LoadingContent extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  isLoading!: boolean;
}
