































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import { createNumberMoreThenValidator, requiredValidator } from '@/services/defaultFormValidators';

@Component({})
export default class DurationTextField extends Vue {
  @PropSync('duration')
  durationValue!: number;

  @PropSync('durationUnit')
  durationUnitValue!: 'minutes' | 'hours';

  rules = {
    duration: [
      requiredValidator,
      createNumberMoreThenValidator(0),
    ],
  };
}
