


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Lesson } from '@/models/entities/Lesson';
import { LessonStatus } from '@/types/Statuses';
import ScheduleTime from '@/views/courses/components/ScheduleTime.vue';
import { Course } from '@/models/entities/Course';
import dayjs from 'dayjs';

@Component({
  components: { ScheduleTime },
})
export default class LessonsList extends Vue {
  @Prop({
    type: Course,
    required: true,
  })
  course!: Course;

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  selectedLessonId!: number;

  @Prop({
    type: Array,
    required: true,
  })
  lessons!: Lesson[];

  @Prop({
    type: Number,
    required: false,
    default: 0,
    validator(value: number): boolean {
      return value >= 0;
    },
  })
  limit!: number;

  LessonStatus = LessonStatus;

  @Prop({
    type: Boolean,
    required: false,
  })
  dense!: boolean;

  @Prop({
    type: Date,
    default: null,
  })
  startDay!: Date | null;

  get lessonSorted(): Lesson[] {
    const lessons = this.lessons.sort((a, b) => {
      if (a.lessonDateDt === b.lessonDateDt) {
        return 0;
      }
      return a.lessonDateDt > b.lessonDateDt ? 1 : -1;
    });
    if (this.startDay) {
      const startDay: string = dayjs(this.startDay).format('YYYYMMDD');

      return lessons.filter((l) => {
        const lessonDay = dayjs(l.lessonDateDt)
          .format('YYYYMMDD');
        return lessonDay >= startDay;
      });
    }
    return lessons;
  }

  get lessonsLimited() {
    if (this.limit === 0) {
      return this.lessonSorted;
    }
    return this.lessonSorted.slice(0, this.limit);
  }
}
