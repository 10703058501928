<template>
  <div
    class="subtitle font-weight-light cyan--text text--darken-2"
    :class="[`mb-${mb}`]"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Subtitle',
  props: {
    mb: {
      type: Number,
      required: false,
      default: 4,
    },
  },
};
</script>

<style scoped lang="scss">
.subtitle {
}
</style>
