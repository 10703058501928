




















































































import {
  Component, VModel, Vue, Watch,
} from 'vue-property-decorator';
import { Lesson } from '@/models/entities/Lesson';
import { LessonStatus } from '@/types/Statuses';
import ScheduleTime from '@/views/courses/components/ScheduleTime.vue';
import DateTextField from '@/components/DateTextField.vue';
import dayjs from 'dayjs';
import { DateAsString, TimeAsString } from '@/types/CommonTypes';
import TimeTextField from '@/components/TimeTextField.vue';
import DurationTextField from '@/components/DurationTextField.vue';
import { VForm } from '@/types/VForm';

interface LessonsEditableList {
  $refs: {
    lessonForm: VForm[];
  };
}

@Component({
  components: {
    DurationTextField,
    TimeTextField,
    DateTextField,
    ScheduleTime,
  },
})
class LessonsEditableList extends Vue {
  @VModel({
    type: Array,
    default: () => [],
  })
  lessons!: Lesson[];
  // lessonsCopy: Lesson[] = [];

  expandedModel: number | '' = '';

  LessonStatus = LessonStatus;
  currentLesson: Lesson | null = null;
  lastUpdatedLesson: Lesson | null = null;

  lessonStartDate: DateAsString = '';
  lessonStartTime: TimeAsString = '';
  // lessonEndTime: TimeAsString = '';
  lessonDuration: number = 40;
  lessonDurationUnit: 'minutes' | 'hours' = 'minutes';

  get lessonSorted() {
    return this.lessons.sort((a, b) => {
      if (a.lessonDateDt === b.lessonDateDt) {
        return 0;
      }
      return a.lessonDateDt > b.lessonDateDt ? 1 : -1;
    });
  }

  @Watch('currentLesson')
  onCurrentLessonChange() {
    if (!this.currentLesson) {
      this.lessonStartDate = '';
      this.lessonStartTime = '';
      return;
    }

    const startDt = dayjs(this.currentLesson.lessonDateDt);

    this.lessonStartDate = startDt.format('YYYY-MM-DD');
    this.lessonStartTime = this.currentLesson.lessonStartTime;
    this.lessonDuration = this.currentLesson.lessonDurationMinutes;
    this.lessonDurationUnit = 'minutes';
  }

  handleCancelClick() {
    this.expandedModel = '';
  }

  handleApplyClick() {
    if (!this.currentLesson) {
      return;
    }
    if (!this.$refs.lessonForm?.[0]?.validate()) {
      return;
    }
    // const dates = mkLessonDates(this.lessonStartDate, this.lessonStartTime, this.lessonEndTime);
    const lessonStartDateDJ = dayjs(`${this.lessonStartDate} ${this.lessonStartTime}`);
    const durationInSeconds = this.lessonDuration * (this.lessonDurationUnit === 'minutes' ? 60 : 3600);
    this.currentLesson.updateDates(lessonStartDateDJ.toISOString(), durationInSeconds);
    this.expandedModel = '';
    this.lastUpdatedLesson = this.currentLesson;
  }

  handleRemoveClick(lesson: Lesson) {
    this.lessons = this.lessons.filter((l) => l !== lesson);
    this.expandedModel = '';
  }
}

export default LessonsEditableList;
