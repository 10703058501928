









































































import dayjs, { OpUnitType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  createDateRuFormatValidator,
  createNumberMoreThenValidator,
  requiredValidator,
  timeFormatValidator,
} from '@/services/defaultFormValidators';
import { RepeatFreq, RepeatInterval } from '@/types/RepeatIntervals';
import { Lesson } from '@/models/entities/Lesson';
import { VForm } from '@/types/VForm';
import TimeTextField from '@/components/TimeTextField.vue';
import DateTextField from '@/components/DateTextField.vue';
import DurationTextField from '@/components/DurationTextField.vue';
// import { mkLessonDates } from '@/services/lessonTimeUtils';

dayjs.extend(customParseFormat);

interface LessonsScheduleAdminTool {
  $refs: {
    form: VForm;
  };
}

@Component({
  components: {
    DurationTextField,
    DateTextField,
    TimeTextField,
  },
})
class LessonsScheduleAdminTool extends Vue {
  isStartDatePickerShown: boolean = false;
  startDate: string = new Date().toISOString()
    .substr(0, 10);

  startDateFormatted: string = '';

  startTime: string = '';

  endTime: string = '';

  duration: number = 40;
  durationUnit: 'minutes' | 'hours' = 'minutes';

  rules = {
    date: [
      requiredValidator,
      createDateRuFormatValidator({ minYear: new Date().getFullYear() }),
    ],
    time: [
      requiredValidator,
      timeFormatValidator,
    ],
    repeatInterval: [
      requiredValidator,
      createNumberMoreThenValidator(0),
    ],
    count: [
      requiredValidator,
      createNumberMoreThenValidator(0),
    ],
    // duration: [
    //   requiredValidator,
    //   createNumberMoreThenValidator(0),
    // ],
  };

  userTimeZone = dayjs.tz.guess();

  repeatFreq: RepeatFreq = 'daily';
  repeatInterval: RepeatInterval = 1;
  lessonsCount: number = 5;

  @Watch('startDate', { immediate: true })
  watchStartDateChanged(val: string) {
    this.startDateFormatted = dayjs(val, 'YYYY-MM-DD')
      .format('DD.MM.YYYY');
  }

  handleDateUpdate(val: string) {
    const djs = dayjs(val, 'DD.MM.YYYY');
    if (djs.isValid()) {
      this.startDate = djs.format('YYYY-MM-DD');
    }
  }

  handleGenerate() {
    if (!this.$refs.form.validate()) {
      return;
    }
    const lessons: Lesson[] = [];
    let currentDate = dayjs(`${this.startDate} ${this.startTime}`);
    const durationInSecs = this.duration * (this.durationUnit === 'minutes' ? 60 : 3600);
    for (let i = 0; i < this.lessonsCount; i++) {
      const lesson = Lesson.createFromDto(
        {
          lessonDate: currentDate.toISOString(),
          lessonDurationSeconds: durationInSecs,
        },
      );

      lessons.push(lesson);

      const unit: OpUnitType = this.repeatFreq === 'daily' ? 'days' : 'weeks';
      currentDate = currentDate.add(this.repeatInterval, unit);
    }

    this.$emit('lessons', lessons);
  }

  get repeatFreqEntries(): { value: RepeatFreq; text: string }[] {
    return [
      {
        value: 'daily',
        text: 'День',
      },
      {
        value: 'weekly',
        text: 'Неделю',
      },
    ];
  }
}

export default LessonsScheduleAdminTool;
