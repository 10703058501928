














import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { HydraError } from '@/api/types/HydraTypes';

@Component({})
export default class ErrorAlert extends Vue {
  @Prop({
    type: [Error, String],
    required: true,
  })
  error!: HydraError | string | null;

  get errorMessage(): string {
    if (!this.error) {
      return '';
    }
    if (typeof this.error === 'string') {
      return this.error;
    }

    return this.error.response?.data?.['hydra:description'] || this.error.message;
  }
}
